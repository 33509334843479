import gql from 'graphql-tag'

export const campaign = gql`
query Campaign($filter: String) {
    campaign(filter: $filter) {
      campaign_id
      campaign_name
      campaign_admanager_data
      campaign_start_datetime
      campaign_end_datetime
      campaign_status_id
      browsers {
        _id {
          os_name
          os_type
        }
      }
      total_data {
        impressions
        clicks
      }
      placements {
        placement_id
        placement_purchase_qty
        placement_dates
      }
    }
  }`;
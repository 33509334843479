import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

import pt from 'vuetify/lib/locale/pt'
Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: { pt },
        current: 'pt',
    },
    theme: {
        themes: {
            light: {
                graySpaceBackground: "#44505E",
                grayMediumSpace: "#BCBCBC",
                lightGray: "EDEDED",
                blueSpace: "#2196F3",
                redSpace: "#FF0000",
                graySpace: "#44505E",
                white: "#FFFFFF",                           
                anchor: "#8c9eff",
                accent: "#2196F3"
            },
            dark: {
                graySpaceBackground: "#1A1A1A",
                grayMediumSpace: "#BCBCBC",
                blueSpace: "#2196F3",
                redSpace: "#FF0000",
                graySpace: "#C9C9C9",
                white: "#FFFFFF",                           
                anchor: "#8c9eff",
                accent: "#2196F3"
            },
        },
    },
});

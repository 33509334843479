import { mapGetters, mapActions } from "vuex"
import CardResumCampaign from "@/components/CardResumCampaign/CardResumCampaign.vue"
import BrowserChart from "@/components/BrowserChart/BrowserChart.vue"
import { formatNumber, camelize, truncate, sumArray } from '@/helpers/filters.js';

export default {
    
    computed: {
		...mapGetters("campaign", ["indexCampaign"]),
        purchaseQty(){
            if(this.indexCampaign?.placements && this.indexCampaign?.placements[0]?.placement_purchase_qty)
                return this.formatMoney(this.indexCampaign?.placements[0]?.placement_purchase_qty) ?? 0
        },
        impressions(){
            if(this.indexCampaign?.total_data?.impressions)
                return this.formatNumber(this.indexCampaign?.total_data?.impressions)
        },
        clicks(){
            if(this.indexCampaign?.total_data?.clicks)
                return this.formatNumber(this.indexCampaign?.total_data?.clicks)
        }
	},
    components: {
        CardResumCampaign,
        BrowserChart

    },
    methods:{
        ...mapActions("campaign",['find']),

        async load(){
            var content = {
                filter: `{ 'campaign_id': ${this.$route.params.campaign_id}}`
              }
            await this.find(content)
        },
        formatNumber(value) {
            if(!value){
              return 0
            }else{
              return parseInt(value).toLocaleString('pt-BR');
            }
        },
        formatMoney(value) {
            if(!value){
              return 0
            }else{
              return parseInt(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
            }
        }
        
    },
    created(){
        this.load()
    }
}
import { getFormats } from "@/services/Dashboard/format"

export default {
	namespaced: true,
    state: {
        formats: [],
    },
    getters: {
		formats: (state) => state.formats,
	},
    mutations: {
        SET_FORMATS(state, payload){
            state.formats = payload
        },
    },
    actions: {
        async allFormats({commit},data){
            var content = {
                filter: `{ 'team_id': 19, 'site_id': ${data}}`
              }
            const res = await getFormats(content)
            commit("SET_FORMATS",res)
        },
    },
}


<template>
  <v-app>
    <transition name="fade" mode="out-in" :duration="350">
      <router-view />
		</transition>
    <Tour/>
  </v-app>
</template>
<script>
import Tour from "@/components/Tour"
export default {
    components:{
      Tour
    },
}
</script>
<style lang="scss" src="@/scss/general.scss"></style>
<template>
    <v-container class="pa-0" :fluid="true">
      <v-row class="m-0">
        <v-app-bar class="v-app-bar" height="100px" v-if="$route.name != 'MakeCampaign' && $route.name != 'InfoPayment' && $route.name != 'ContentCampaign'"
          color="#fff" elevation="0" :fixed="false">
          <v-container class="pa-0">
            <v-row class="justify-space-between">
              <v-col cols="6" sm="9" md="4" lg="3" xl="3" class="pa-0">
                <router-link to="/dashboard">
                  <v-img max-width="175" :src="site?.site_logo"></v-img>
                </router-link>
              </v-col>
              <v-col cols="3" sm="3" md="4" lg="3" xl="3" class="d-flex justify-end align-center pa-0">
                <v-btn text color="#d50000" @click="logoutRover" class="pr-0 ml-5">
                  <span class="material-icons-outlined">
                  logout
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
        <v-app-bar class="v-app-bar" height="50px" v-else
          color="#fff" elevation="0" :fixed="false">
          <v-container class="pa-0">
            <v-row class="m-0">
              <v-col cols="2" class="p-0">
                <v-btn text color="blueSpace" @click="$router.go(-1)" class="p-0">
                  <span class="material-icons-outlined">
                      keyboard_backspace
                  </span>
                  Voltar
                </v-btn>
              </v-col>
              <v-col cols="10" class="p-0 d-flex justify-end pr-0">
                <ButtonTour/>
              </v-col>
            </v-row>
          </v-container>
        </v-app-bar>
      </v-row>
    </v-container>
  </template>
<script>
import logoDefault from "../../assets/imgs/Caixa.png"
import { logout } from "@/services/Auth/auth";
import ButtonTour from "@/components/ButtonTour/index.vue"

import { mapGetters } from "vuex"
export default {
    name:'Toolbar',
    data: () => ({
        logoDefault
    }),
    computed: {
      ...mapGetters("auth", ["site"]),
    },
    components: {
      ButtonTour
    },
    methods:{
      logoutRover(){
        logout()
        localStorage.clear();
        this.$router.push({ name: "Login" });
      },
    },
}
</script>
<style lang="scss" scoped src="@/scss/toolbar.scss"></style>
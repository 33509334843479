import gql from 'graphql-tag';

export const dataTeam = gql`
    query Team($filter: String) {
        team (filter: $filter){
        team_id
        team_name
        team_active
        team_emails
        team_logo
        team_support_number
        team_support_email
        team_admin_url
        team_analytics_url
        team_address
        team_domain
        team_logo_negative
        team_bg_1
        team_bg_2
        }
    }`;
<template>
    <v-card min-height="100" color="#F3F4F6" class="d-flex align-center border" flat>
        <v-container class="white--graySpaceBackground">
            <h3>
                {{ name }}
            </h3>
            <h1>
                {{ value ?? 0 }}
            </h1>
        </v-container>
    </v-card>
</template>
<script>
export default {
    name:'CardResumCampaign',
    props: {
        value: String,
        name: String,
    }
}
</script>
<style scoped>
h1{
    font-size: 36px;
    font-weight: 300;
}
h3{
    font-weight: 300;
}
.border{
    border-radius: 6px !important;
}
</style>
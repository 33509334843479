import { getCampaigns } from "@/services/Dashboard/getCampaigns"
import { findCampaign } from "@/services/Dashboard/findCampaign"

export default {
	namespaced: true,
    state: {
        campaigns: [],
        sketchCampaigns: [],
        campaign: {},
        indexCampaign: {},
    },
    getters: {
		campaigns: (state) => state.campaigns,
		sketchCampaigns: (state) => state.sketchCampaigns,
		campaign: (state) => state.campaign,
		indexCampaign: (state) => state.indexCampaign,
	},
    mutations: {
        GET_CAMPAIGNS(state, payload){
            state.campaigns = payload
        },
        GET_SKETCH_CAMPAIGNS(state, payload){
            state.sketchCampaigns = payload
        },

        FIND_CAMPAIGNS(state, payload){
            state.indexCampaign = payload
        },

        SET_CAMPAIGN(state, payload){
            state.campaign.push(payload)
        },

        RESET_CAMPAIGN(state){
            state.campaign = null
        },

        STEP_CAMPAIGN(state, payload){
            state.campaign = {...payload,...state.campaign}
        },
    },
    actions: {
        async allCampaigns({commit}){
            var content = {
                filter: {campaign_status_id: [1,2]}
              }
            const res = await getCampaigns()
            let campaignFormat = res.filter(item => {
                if(item.placements.length )
                    return item
            })
            
            let sketchCampaignFormat = res.filter(item => {
                if(!item.placements.length )
                    return item
            })
            
            commit("GET_CAMPAIGNS",campaignFormat)
            commit("GET_SKETCH_CAMPAIGNS", sketchCampaignFormat)
        },

        nextStepCampaigns({commit},data){
            commit("STEP_CAMPAIGN",data)
        },

        reset({commit}){
            commit("RESET_CAMPAIGN")
        },
        
        async find({commit},data){
            const res = await findCampaign(data)
            commit("FIND_CAMPAIGNS",res)
        }
    },
}


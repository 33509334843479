import gql from 'graphql-tag'

export const createPlacement = gql`
mutation createPlacement($content: PlacementContent!) {
  createPlacement(content: $content) {
    code
    data {
      placement_id
    }
    message
    success
  }
}`;
import apollo from '@/plugins/apollo';
import { UPDATE_CAMPAIGN } from '@/graphql/Dashboard/updateCampaign';

export const updateCampaign = async (variables) => {
  const response = await apollo.mutate({
    mutation: UPDATE_CAMPAIGN,
    variables,
  });
  
  return response.data.updateCampaign.data;
};
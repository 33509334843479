export default {
	namespaced: true,
    state: {
        message: null,
        type: null,
        show: false
    },
    getters: {
		message: (state) => state.message,
		type: (state) => state.type,
		show: (state) => state.show,
	},
    mutations: {
        SET_NOTIFY(state, payload){
            state.message = payload.message
            state.type = payload.type
        },
        SET_SHOW(state, payload){
            state.show = payload
        },
    },
    actions: {
        notify({ commit }, data) {
			commit("SET_NOTIFY", data)
		},

        notifyShow({ commit }, data) {
			commit("SET_SHOW", data)
		},
    },
}

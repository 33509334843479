<template>
    <v-btn 
        width="90"
        @click="handleTour"
        data-v-step="0" 
        color="blueSpace" 
        class="white--text"
        depressed
    >
        Tour
    </v-btn>
</template>  
<script>
import { mapActions, mapGetters } from "vuex"

export default {
    name:'ButtonTour',
    data: () => ({
      allSteps: [
          {
              step: 'create-campaign',
              target: '[data-v-step="0"]',  // We're using document.querySelector() under the hood
              header: {
                  title: 'Bem vindo ao Rover',
              },
              content: 'Aqui você irá receber um tour completo de como criar uma camopanha',
              params: {
                  placement: 'bottom',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-campaign',
              target: '[data-v-step="1"]',
              content: 'A primeira informção que precisamos informar é o nome da campanha',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-campaign',
              target: '[data-v-step="2"]',
              content: 'Clique aqui para ir para a proxima etapa',
              params: {
                  placement: 'top',
                  highlight: true
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-campaign',
              target: '[data-v-step="3"]',
              content: 'Nessa etapa você irá segmentar a campanha, ou seja, informar a região de anúncio, o publico e a faixa etária, preencha os campos para avançar',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-campaign',
              target: '#v-step-4',
              content: 'Clique aqui para ir para proxima etapa',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-payment',
              target: '[data-v-step="5"]',
              content: 'Informe aqui como você deseja veicular sua campanha (por dia ou em um determinado periodo), o valor investido e o método de pagamento, preencha os campos para avançar',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-payment',
              target: '[data-v-step="6"]',
              content: 'Clique aqui para ir para proxima etapa',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-creativo',
              target: '[data-v-step="7"]',
              content: 'Nessa etapa você irá informar a url para qual será redirecionada quando um úsuario clicar no creativo, preencha os campos para avançar.',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-creativo',
              target: '[data-v-step="8"]',
              content: 'Clique aqui para ir para proxima etapa',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-creativo',
              target: '[data-v-step="9"]',
              content: 'Nessa etapa você irá adicionar o creativo que será veiculada nos portal, escolha uma imagem e ajuste-a para avançar.',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          },
          {
              step: 'create-creativo',
              target: '[data-v-step="10"]',
              content: 'Clique aqui para enviar o creativo',
              params: {
                  placement: 'top',
              },
              before: type => new Promise((resolve, reject) => {
                  localStorage.setItem('tour', true) 
                  resolve('foo')
              })
          }
      ],
    }),
    computed: {
      ...mapGetters("tour", ["steps"]),
    },
    methods:{
      ...mapActions("tour", ["generateSteps"]),

      async handleTour(){
        if(this.$tours['myTour'].$route.name == 'MakeCampaign'){
          // this.$tours['myTour'].steps = this.allSteps
          await this.generateSteps(this.allSteps)

        } else if(this.$tours['myTour'].$route.name == 'MakeCampaign' && this.$tours['myTour'].$route.params?.campaign_id){
          const res = await this.allSteps.filter(item=> {
            if(item.step != "create-creativo") return item
          })
          
          await this.generateSteps(res.shift())
          // this.$tours['myTour'].steps = res
          
        } else if(this.$tours['myTour'].$route.name == 'InfoPayment'){
          const res = await this.allSteps.filter(item=> {
            if(item.step == "create-payment") return item
          })
          await this.generateSteps(res)
          // this.$tours['myTour'].steps = res
          
        } else if(this.$tours['myTour'].$route.name == 'ContentCampaign'){
          const res = await this.allSteps.filter(item=> {
            if(item.step =="create-creativo") return item
          })

          await this.generateSteps(res)
          // this.$tours['myTour'].steps = res
        }

        this.$tours['myTour'].start()
      }
    },
}
</script>
import apollo from '@/plugins/apollo';
import { createCampaign } from '@/graphql/Dashboard/createCampaign';

export const storeCampaigns = async (variables) => {
  const response = await apollo.mutate({
    mutation: createCampaign,
    variables,
  });
  
  return response.data.createCampaign.data;
};
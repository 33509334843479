import gql from 'graphql-tag'

export const createCreativeGroup = gql`
mutation createCreativeGroup($content: CreativeGroupContent!) {
  createCreativeGroup(content: $content) {
    code
    success
    message
    data {
      creative_group_id
    }
  }
}`;
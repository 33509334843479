import Vue from "vue";
import VueRouter from "vue-router";

import { dataTeams } from "@/services/Dashboard/dataTeam";
import { getSite } from "@/services/Dashboard/getSite";
import { loggedUser } from "@/plugins/apollo";

import Auth from '../views/Auth/index.vue'
import Login from '../views/Auth/Login/index.vue'
import Register from '../views/Auth/Register/index.vue'
import RecoveryPassword from '../views/Auth/RecoveryPassword/index.vue'


import Layout from '../components/Layout/index.vue'
import CreateCampaign from '../views/Dashboard/CreateCampaign/index.vue'
import CreateContent from '../views/Dashboard/CreateContent/index.vue'
import CreatePayment from '../views/Dashboard/CreatePayment/index.vue'
import ListCampaign from '../views/Dashboard/ListCampaign/index.vue'
import FindCampaign from '../views/Dashboard/Campaign/index.vue'

import { mapActions } from "vuex"
mapActions("auth",['getDataTeam']),

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Auth,
    redirect: { name: "Login" },
    children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      //beforeEnter: isFirstAccess,
      meta: {
        guest: true,
      },
    },
    {
      path: "/recuperar-senha",
      name: "RecoveryPassword",
      component: RecoveryPassword,
      //beforeEnter: isFirstAccess,
      /* meta: {
        guest: true,
      }, */
    },
    {
      path: "/registrar",
      name: "Register",
      component: Register,
      //beforeEnter: isFirstAccess,
      /* meta: {
        guest: true,
      }, */
    },
    ]
  },
  {
    path: "/dashboard",
    component: Layout,
    redirect: { name: "ListCampaign" },
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "ListCampaign",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: ListCampaign,
        meta: { accessRoute: true },
      },
      {
        path: "/campanha/:campaign_id",
        name: "FindCampaign",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: FindCampaign,
        meta: { accessRoute: true },
      },
      {
        path: "/criar-campanha/:campaign_id?",
        name: "MakeCampaign",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CreateCampaign,
        meta: { accessRoute: true },
      },
      {
        path: "/orcamento/:campaign_id?",
        name: "InfoPayment",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CreatePayment,
        meta: { accessRoute: true },
      },
      {
        path: "/:campaign_id/conteudo",
        name: "ContentCampaign",
        // route level code-splitting
        // this generates a separate chunk (About.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: CreateContent,
        meta: { accessRoute: true },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
});


let url = window.location.hostname;

router.beforeEach( async (to, from, next) => {
  await router?.app?.$store?.dispatch("auth/setLoad", true);
  try {
    const variables = { filter: `{ 'team_id' : '19' }` };
    const response = await dataTeams(variables);
    await router.app.$store.dispatch("auth/getDataTeam", response);
    const variablesSite = { filter: "{'site_domains': 'localhost', 'site_rover_active': true}" };
    const responseSite = await getSite(variablesSite);
    await router.app.$store.dispatch("auth/getSite", responseSite);
    document.title = responseSite.site_name
  } catch (error) {
  }
  
  if( to.matched.some(route => route.meta.requiresAuth )){
    if(to.query?.s){
        localStorage.setItem('sessionId', to.query?.s) 
    }
    const loginRoute = {
        path: '/',
        query: { redirect: to.fullPath }
    }
    if(localStorage.sessionId){
        try {
            const user = await loggedUser({ fetchPolicy: 'network-only' })
            await router.app.$store.dispatch("auth/getUser", user);
            window.localStorage.setItem('redirect', user.redirect)
            if (to.matched.some(route => route.meta.accessRoute)) {
                if(user.redirect !== null){
                    return next(from.path)
                }else{
                    return next()
                }
            }
            
            await router.app.$store.dispatch("auth/setLoad", false);
            return next()
        } catch (error) {
          await router.app.$store.dispatch("auth/setLoad", false);
            return next(loginRoute)
        }
    }
    await router.app.$store.dispatch("auth/setLoad", false);
    return next(loginRoute)
  }
  await router.app.$store.dispatch("auth/setLoad", false);
return next()
})


export default router;

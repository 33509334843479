import apollo, { onLogin } from '@/plugins/apollo';
import { passwordRecovery } from '@/graphql/Auth/passwordRecovery';

export const recoveryPassword = async variables => {
    const response = await apollo.mutate({
        mutation: passwordRecovery,
        variables
    })
    
    return response
}
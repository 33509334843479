import gql from 'graphql-tag'

export const passwordRecovery = gql`
mutation Password_recovery($userEmail: String) {
  password_recovery(user_email: $userEmail) {
    data
    code
    message
    success
  }
}`
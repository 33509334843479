<template>
    <v-card height="100" :color="card" class="d-flex align-center" :style="{color: text}" @click="$emit('click')" flat>
        <v-container class="d-flex align-center">
            <div class="eclipse rounded-circle" :style="{ backgroundColor: eclipse }"></div>
            {{ name }}
        </v-container>
    </v-card>
</template>
<script>
export default {
    name:'CardCampaign',
    props: {
        text: String,
        card: String,
        eclipse: String,
        name: String,
    }
}
</script>
<style scoped>
.input-field {
    width: 240px;
}

#campaignName {
    padding-top: 40% !important;
}

.v-list-item {
    color: #2196F3;
}

.eclipse {
    width: 11px;
    height: 11px;
    margin-right: 5px;
}
</style>
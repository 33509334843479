var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-container',{staticClass:"h-100"},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"d-flex justify-space-between py-1"},[_c('p',{staticClass:"font-weight-regular"},[_vm._v("Conteúdo")]),_c('p',{staticClass:"font-weight-regular"},[_vm._v("3/3")])]),(_vm.step == 0)?_c('div',{attrs:{"data-v-step":"7"}},[_c('v-text-field',{attrs:{"label":"Digite a url de redirecionamento","solo":"","color":"blueSpace","clearable":"","required":"","flat":"","hide-details":true},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}})],1):_c('div',[_c('label',{attrs:{"for":"selecao-arquivo"},on:{"click":_vm.openFileExplorer}},[_c('span',{staticClass:"material-icons-outlined",staticStyle:{"margin-right":"5px"}},[_vm._v(" upload ")]),_vm._v(" "+_vm._s(_vm.file?.name ?? 'Enviar arquivo')+" ")]),_c('input',{ref:"fileInput",attrs:{"id":"inputFile","type":"file","multiple":""},on:{"change":_vm.onChange}}),_c('div',{staticClass:"w-100 d-flex align-center justify-center mt-2"},[(_vm.img)?_c('cropper',{staticClass:"cropper",attrs:{"src":_vm.img,"resize-image":{
                            adjustStencil: false,
                        },"stencil-props":{
                            movable: false,
                            resizable: false,
                            handlers: {},
                            lines: {},
                        },"stencil-size":{
                            width: _vm.format.format_size_width,
                            height: _vm.format.format_size_height
                        },"min-height":_vm.format.format_size_height,"min-width":_vm.format.format_size_width,"image-restriction":"stencil"},on:{"change":_vm.change}}):_vm._e()],1),(_vm.img)?_c('div',{staticClass:"w-100 d-flex align-center justify-center mt-5"},_vm._l((_vm.formats),function(item){return _c('v-btn',{staticClass:"text-caption white--text mr-2",attrs:{"color":"blueSpace","height":"55","width":"88","depressed":""},on:{"click":function($event){return _vm.changeFormat(item)}}},[_c('div',{staticClass:"object",style:({height: item.format_size_height+'px', width: item.format_size_width+'px',scale:0.06})})])}),1):_vm._e()])]),_vm._l((_vm.fileRender),function(filer){return _c('v-col',{staticStyle:{"display":"none"},attrs:{"data-v-step":"9"}},[_c('img',{ref:"imgs",refInFor:true,attrs:{"src":filer,"alt":""}})])})],2),_c('v-row',[(_vm.url != null && _vm.step == 1)?_c('v-col',{staticClass:"d-flex justify-center align-center mt-1"},[(!_vm.isLoading)?_c('ButtonNextStep',{attrs:{"data-v-step":"10"},on:{"click":_vm.createCampain}}):_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-col',{staticClass:"d-flex justify-center align-center mt-1"},[_c('ButtonNextStep',{attrs:{"data-v-step":"8"},on:{"click":_vm.validateURL}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
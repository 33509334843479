import { getUf, getCity } from '@/services/ApiRegions/api'
import ButtonNextStep from '../../../components/ButtonNextStep/index.vue'
import { mapActions, mapGetters } from "vuex"

export default {
    data: () => ({
        step: 0,
        range: [0,0],
        min: 18,
        max: 60,
        form: {
            campaign_name:'',
            city: null,
            state: null,
            public: [],
            age_ranger: [0, 0]
        },
        ufSelected: null,
        uf: [],
        city: [],
        stepConfiglocation: [
            { text: 'Brasil todo', icon: 'mdi-web', type: 'checkbox' },
            { text: 'Selecione a cidade', icon: 'mdi-map-marker', type: 'select' },
            { text: 'Gênero', icon: 'mdi-account', type: 'select' },
            { text: 'Idade', icon: 'mdi-calendar', type: 'slide' },
        ],
        menu2: false,
        loading: false
    }),
    components:{
        ButtonNextStep
    },
    computed: {
		...mapGetters("campaign", ["campaign", "indexCampaign"]),
	},
    watch: {
		ufSelected(value) {
            if(value && value != "Brasil todo"){
                getCity(this.ufSelected).then(result => {{
                this.city = result.map(city => city.nome)}})
            }
		},
	},
    methods:{
        ...mapActions("campaign",["nextStepCampaigns", "find", "reset"]),

        createCampain() {
            if (this.step != 1) {
                if(this.form.campaign_name){
                    this.step = this.step + 1
                    if(this.$tours['myTour'].currentStep > -1){
                        setTimeout(()=>{
                            this.$tours['myTour'].nextStep()
                        },300)
                    }
                }
            } else {
                this.form.state = this.ufSelected
                if(this.ufSelected && this.form.public.length){
                    this.nextStepCampaigns(this.form)
                    this.$router.push({ name: "InfoPayment" });
                    if(this.$tours['myTour'].currentStep > -1){
                        setTimeout(()=>{
                            this.$tours['myTour'].nextStep()
                        },700)
                    }
                }
            }
        },
        updateCampain() {
                if(this.ufSelected && this.form.public.length || this.form.city && this.form.state && this.form.public.length){
                    this.form.state = this.ufSelected
                    this.nextStepCampaigns(this.form)
                    this.$router.push({ name: "InfoPayment", params: { campaign_id: this.$route.params.campaign_id } });
                }
        },
        async load(){
            this.loading = true
            
            getUf()
            .then(result=>{
                this.uf = result.map(uf => uf.sigla)
            })

            if(this.$route.params.campaign_id){
                let content = {
                    filter: `{ 'campaign_id': ${this.$route.params.campaign_id}}`
                  }
                await this.find(content)
                this.step = 1
                let campaign_admanager_data = JSON.parse(this.indexCampaign.campaign_admanager_data)
                              
                this.form.campaign_name = this.indexCampaign.campaign_name
                this.form.city = campaign_admanager_data.city
                this.form.state = campaign_admanager_data.state
                this.form.public = campaign_admanager_data.public
                this.form.age_ranger = [campaign_admanager_data.age_ranger_start , campaign_admanager_data.age_ranger_end]
                this.ufSelected = campaign_admanager_data.state  
            }else{
                this.step = 0
                this.reset()
            }

            this.loading = false
        }
    },
    created(){
        this.load()
        if(!localStorage?.tour){
            this.$tours['myTour'].start()
        }
    }
}
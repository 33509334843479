import Vue from "vue"
import axios from "axios";

const api = axios.create({
	baseURL: 'https://servicodados.ibge.gov.br/api/v1/localidades/',
})

const getUf = async ()=>{
	try {
		const res = await api.get("estados")
		return res.data
	} catch (err) {
		throw {
			message: "Erro interno do servidor",
		}
	}
}
const getCity = async (uf) => {
	const res = await api.get(`estados/${uf}/distritos`)
	return res.data
}

export {
	getUf,
	getCity
}
import gql from 'graphql-tag'

export const site = gql`
  query Site($filter: String) {
    site (filter: $filter) {
      site_id
      site_type_id
      site_name
      site_logo
      site_website
      site_mediakit_url
      site_adserver_macro
      site_thirdparty_token
      site_active
      site_domains
    }
  }`;
import apollo from '@/plugins/apollo';
import { storeAdvertiser } from '@/graphql/Auth/storeAdvertiser';

export const register = async variables => {
    const response = await apollo.mutate({
        mutation: storeAdvertiser,
        variables
    })
    
    return response.data.roverSignup
}
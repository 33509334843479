import gql from 'graphql-tag'

export const formats = gql`
query Format($filter: String) {
  formats(filter: $filter) {
    format_size_height
    format_size_width
    format_name
    format_id
    site_id
    tag_id
    team_id
    format_active
    format_deleted_at
    format_weight_gif
    format_weight
    format_type_id
    format_time
    format_template_url
    format_obs
    format_size_expanded_height
    format_size_expanded_width
    format_is_safeframe
    format_loop
    format_modified_on
    format_geometry
  }
}`;
import gql from 'graphql-tag'

export const createChannel = gql`
mutation createChannel($content: ChannelContent!) {
    createChannel(content: $content) {
        code
        success
        message
        data {
        channel_id
        }
    }
}`;
import gql from 'graphql-tag'

export const campaigns = gql`
query Campaigns {
    campaigns {
      campaign_id
      campaign_status_id
      campaign_name
      campaign_status {
        status_descr
        campaign_status_id
        status_color
      }
      advertiser {
        account_id
        account_name
      }
      placements {
        placement_id
        campaign_id
        format_id
        channel_id
        purchase_type_id
        placement_status_id
        placement_purchase_qty
        placement_name
        placement_purchase_group
      }
    }
  }`;
import apollo from '@/plugins/apollo';
import { createCreative } from '@/graphql/Dashboard/createCreative';

export const storeCreative = async (variables) => {
  const response = await apollo.mutate({
    mutation: createCreative,
    variables
  });

  return response.data.createCreative.data;
};
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import campaign from './modules/campaign.module'
import auth from './modules/auth.module'
import notify from "./modules/notify.module";
import format from "./modules/format.module";
import tour from "./modules/tour.module"

export default new Vuex.Store({
  modules: {
    campaign,
    auth,
    notify,
    format,
    tour
  },
});

import CardCampaign from '@/components/CardCampaign/CardCampaign.vue';
import { mapActions, mapGetters } from "vuex"
export default {
    namee: 'ListCampaign',
    data: () => ({
        loading: false
    }),
    components:{
        CardCampaign
    },
    computed: {
		...mapGetters("campaign", ["campaigns", "sketchCampaigns"]),
	},
    methods:{
        ...mapActions("campaign",['allCampaigns']),

        createCampain() {
            this.$router.push({ name: "MakeCampaign" });
        },

        async load(){
            this.loading = true
            await this.allCampaigns()
                .then(() => this.loading = false)
                .catch(() => this.loading = false)
        },

        colorCard(value){
            return value != 2 ? '#E0E0E0' : '#44505E'
        },

        colorText(value){
            return value != 2 ? '#44505E' : '#FFFFFF'
        }
    },
    beforeMount(){
        this.load()
    }
}
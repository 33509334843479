import gql from 'graphql-tag'

export const createCampaign = gql`
mutation createCampaign($content: CampaignContent!) {
  createCampaign(content: $content) {
    code
    data {
      campaign_id
      campaign_name
    }
    message
    success
  }
}`;
import logoDefault from "../../../assets/imgs/Caixa.png"
import { formatError } from "@/helpers/filters";
import { mapGetters } from "vuex"
import { recoveryPassword } from "@/services/Auth/recoveryPassword";

export default {
    data: () => ({
        user: {
            userEmail: '',
        },
        errorMessageLogin: '',
        dialog: false,
        type: null,
        logoDefault,
        rulesEmail: [
            (value) => !!value || "Campo obrigatório",
            (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email inválido";
            },
        ],
        isLoading: false
    }),
    computed: {
		...mapGetters("auth", ["dataTeam","load"]),
	},
    methods: {
        async recovery() {
            if (!this.user.userEmail) {
                this.errorMessageLogin = formatError("Informe um email!")
                this.type = 'error'
                this.dialog = true
                setTimeout(() => { this.dialog = false }, 3000);
            } else {
                try {
                    localStorage.clear();
                    this.isLoading = true

                    const res = await recoveryPassword(this.user)
                    if(res.data.password_recovery.success){
                        this.type = 'success'
                        this.errorMessageLogin = formatError(res.data.password_recovery.message)
                        this.dialog = true
                        setTimeout(() => { 
                            this.dialog = false 
                            this.$router.push({ name: "Login" });
                        }, 3000);
                      }else{
                        this.errorMessageLogin = formatError(res.data.password_recovery.message)
                        this.type = 'error'
                        this.dialog = true
                        setTimeout(() => { this.dialog = false }, 3000);
                      }
                      this.isLoading = false
                } catch (error) {
                    this.isLoading = false
                    this.errorMessageLogin = formatError(error.message)
                    this.type = 'error'
                    this.dialog = true
                    setTimeout(() => { this.dialog = false }, 3000);
                    // alert('como é amigo:',error)
                }
            }
        }
    }
}
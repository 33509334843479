import apollo from '@/plugins/apollo';
import { campaign } from '@/graphql/Dashboard/campaign';

export const findCampaign = async (variables) => {
  const response = await apollo.query({
    query: campaign,
    variables
  });
  
  return response.data.campaign;
};
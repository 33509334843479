import apollo from '@/plugins/apollo';
import { createPlacement } from '@/graphql/Dashboard/createPlacement';

export const storePlacement = async (variables) => {
  const response = await apollo.mutate({
    mutation: createPlacement,
    variables
  });
  
  return response.data.createPlacement.data;
};
import ButtonNextStep from '@/components/ButtonNextStep/index.vue'
import { storePlacement } from '@/services/Dashboard/createPlacement'
import { storeChannel } from '@/services/Dashboard/createChannel'
import { storeCreative } from '@/services/Dashboard/createCreative'
import { storeCreativeGroup } from '@/services/Dashboard/createCreativeGroup'
import { storeUploadCreative } from '@/services/Dashboard/createUploadCreative'
import { mapGetters, mapActions } from "vuex"
import moment from 'moment/moment'
import { Cropper } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';

export default {
    data: () => ({
        file: null,
        placements: [],
        url: null,
        isloading: false,
        fileRender:[],
        step: 0,
        format: {
            id: 1, height: 250,
            width: 300
        },
        filesSend: null,
        img: null,
        image: null
    }),
    components: {
        ButtonNextStep,
        Cropper,
    },
    computed: {
		...mapGetters("campaign", ["campaign"]),
		...mapGetters("auth", ["site"]),
		...mapGetters("format", ["formats"]),
	},
    methods:{
        ...mapActions("notify",['notify','notifyShow']),
        ...mapActions("format",['allFormats']),
        ...mapActions("campaign",['allCampaigns']),

        async load(){
            await this.allFormats(this.site.site_id).then(r=>{
                this.format = this.formats[0]
            })
        },
        change({ coordinates, canvas, image }) {
            this.image = canvas.toDataURL();
            const file = new File([this.image], 'imagem_recortada.png', { type: 'image/png' });

            this.image = file
		},
        changeFormat(value) {
            this.format = value
            
            if(this.url != null){
                this.step++
            } else{
                this.notify({message: "Informe a url da peça" , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
            }
        },
        openFileExplorer() {
            this.$refs.fileInput.click();
        },
        onChange(e) {
            var files = e.target.files[0] || e.dataTransfer.files[0];
            if (files.size > 5000000) {
                alert('Os arquivos devem ter tamanho máximo de 5MB')
                return;
            }
            this.img = URL.createObjectURL(files)
            this.filesSend = files
            // this.createFile(files);
        },
        async createFile(file) {
            // for(let file of files) {
                if (!file.type.match('image.*') && !file.type.match('video.*')) {
                    alert('Verifique as extensões dos arquivos');
                    this.dragging = false;
                    return;
                } else if (file.size > 5000000) {
                    alert('Os arquivos devem ter tamanho máximo de 5MB')
                    this.dragging = false;
                    return;
                } else {
                    this.fileRender.push(URL.createObjectURL(file))
                }

                file.placement_order = this.placements.length + 1;
                this.file = file;
                this.dragging = false;
                await this.placements.push(
                    {
                        previewDialog: false,
                        placement_file: file,
                        placement_file_type: file.type,
                        placement_file_name: file.name,
                        placement_name: file.name,
                        placement_file_weight: (file.size/1000) + 'KB',
                        format: this.format,
                        format_size_width: this.format.format_size_width,
                        format_size_height: this.format.format_size_height,
                        placement_dates: [this.campaign.campaign_start_datetime, this.campaign.campaign_end_datetime],
                        placement_purchase_value: this.campaign.value,
                        placement_url: this.url,
                        site_id: this.site.site_id,
                        placement_status: {status_descr: 'Enviando Material'}
                    }
                );
                return
            // }
        },
        async createCampain(){
                await this.createFile(this.image)
                    .then(async ()=> {
                        for (let key in this.placements) {
                            let placement = this.placements[key];
                                placement.format = this.format
                            // if (placement.placement_file !== undefined) formData.append('files', placement.placement_file);
                        }
                        this.isloading = true
            
                        for (let placement of this.placements) {
                            if (placement.placement_dates[0]) {
                                if (!this.campaign.campaign_start_datetime) this.campaign.campaign_start_datetime = moment(placement.placement_dates[0], "YYYY-MM-DD").toDate();
                                if (this.campaign.campaign_start_datetime > moment(placement.placement_dates[0], "YYYY-MM-DD").toDate()) this.campaign.campaign_start_datetime = moment(placement.placement_dates[0], "YYYY-MM-DD").toDate();
                            }
                            if (placement.placement_dates[1]) {
                                if (!this.campaign.campaign_end_datetime) this.campaign.campaign_end_datetime = moment(placement.placement_dates[1], "YYYY-MM-DD").toDate();
                                if (this.campaign.campaign_end_datetime < moment(placement.placement_dates[1], "YYYY-MM-DD").toDate()) this.campaign.campaign_end_datetime = moment(placement.placement_dates[1], "YYYY-MM-DD").toDate();
                            }
                        }
                    
                        for (let placement of this.placements) {
                            let channel_variables = {
                                content: {
                                    campaign_id: parseInt(this.$route.params.campaign_id) ?? this.campaign.campaign_id,
                                    site_id: this.site.site_id,
                                    channel_descr: this.campaign.campaign_name + " - " + placement.format.format_name,
                                    channel_purchased_quantity: 0,
                                    channel_amount_invested: placement.placement_purchase_value
                                }
                            }

                            if(placement.channel_id) channel_variables.content.channel_id = placement.channel_id;
                            await storeChannel(channel_variables)
                            .then(async response => {
                                let channel = response;
                                
                                let placement_variables = {
                                    content: {
                                        campaign_id: parseInt(this.$route.params.campaign_id) ?? this.campaign.campaign_id,
                                        format_id: placement.format.format_id ?? this.format.id,
                                        channel_id: channel.channel_id,
                                        placement_dates: placement.placement_dates.join("/"),
                                        purchase_type_id: 1,
                                        placement_purchase_qty: this.campaign.value, 
                                        placement_name: this.campaign.campaign_name + " - " + placement.format.format_name
                                    }
                                }
                                if(placement.placement_id) placement_variables.content.placement_id = placement.placement_id;
                                await storePlacement(placement_variables).then( async response => {
                                    let placement_inserted = response;
            
                                    let creative_group_variables = {
                                        content: {
                                            campaign_id: parseInt(this.$route.params.campaign_id) ?? this.campaign.campaign_id,
                                            group_name: placement_variables.content.placement_name
                                        }
                                    }
                                    await storeCreativeGroup(creative_group_variables)
                                    .then(async response => {
                                        let creative_group = response
            
                                        let creative_variables = {
                                            content: {
                                                campaign_id: parseInt(this.$route.params.campaign_id) ?? this.campaign.campaign_id,
                                                placement_id: placement_inserted.placement_id,
                                                creative_group_id: creative_group.creative_group_id,
                                                creative_redirect_url: placement.placement_url
                                            }
                                        }
                                        await storeCreative(creative_variables)
                                            .then(async response => {
                                                await storeUploadCreative({ creativeId: response.creative_id, file: this.filesSend }).then(async res=>{
                                                    await this.allCampaigns()
                                                    this.notify({message: 'Creativo criado com sucesso!',type: 'success'})
                                                    this.notifyShow(true)
                                                    setTimeout(() => { this.notifyShow(false) }, 3000);
                                                    this.$router.push({ name: "ListCampaign" })
                                                })
                                                
                                            }).catch(error => {
                                                this.notify({message: error.message , type: 'error'})
                                                this.notifyShow(true)
                                                setTimeout(() => { this.notifyShow(false) }, 3000);
                                            });
                                    }).catch(error => {
                                        this.notify({message: error.message , type: 'error'})
                                        this.notifyShow(true)
                                        setTimeout(() => { this.notifyShow(false) }, 3000);
                                    });
                                }).catch(error => {
                                    this.notify({message: error.message , type: 'error'})
                                    this.notifyShow(true)
                                    setTimeout(() => { this.notifyShow(false) }, 3000);
                                });
                            }).catch(error => {
                                this.notify({message: error.message , type: 'error'})
                                this.notifyShow(true)
                                setTimeout(() => { this.notifyShow(false) }, 3000);
                            });
                        }
                        
                    })
                    .catch(e=>{
                        this.notify({message: e.message , type: 'error'})
                        this.notifyShow(true)
                        setTimeout(() => { this.notifyShow(false) }, 3000);
                    })
                    
                    this.isloading = false
        },
        isURL(url) {
            const urlPattern = /^(https?:\/\/|www\.)/;
            return urlPattern.test(url);
        },

        validateURL() {
            if (!this.url) {
                this.notify({message: "URL é obrigatório" , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
                return
            }
            if (!this.isURL(this.url)) {
                this.notify({message: "A URL é inválida" , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
                return
            }
            if (!this.url.includes("https://") && !this.url.includes("www")) {
                this.notify({message: "A URL precisa ter 'https://' ou 'www'" , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
                return
            }
            return this.step++;
            
        },
    },
    beforeMount(){
        this.load()
    }
}
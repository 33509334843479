<template>
    <div>
        <v-tour name="myTour" :steps="steps" class="overlay">
            <template slot-scope="tour">
                <transition name="fade">
                    <v-step 
                        v-if="tour.steps[tour.currentStep]" 
                        :key="tour.currentStep" 
                        :step="tour.steps[tour.currentStep]"
                        :previous-step="tour.previousStep" 
                        :next-step="tour.nextStep" 
                        :stop="tour.stop" 
                        :skip="tour.skip"
                        :is-first="tour.isFirst" 
                        :is-last="tour.isLast" 
                        :labels="tour.labels"
                        :highlight="true"
                    >
                        <template>
                            <div slot="actions" >
                                <v-btn outlined color="white" @click="tour.skip" v-if="tour.currentStep != 2 &&  tour.currentStep != 4 && tour.currentStep != 6 && tour.currentStep != 8 && tour.currentStep != 10"
                                    class="ma-1 body-1 font-weight-regular">Pular tour</v-btn>
                                <v-btn outlined color="white" @click="tour.previousStep" v-if="!tour.isFirst && tour.currentStep != 2 &&  tour.currentStep != 4 && tour.currentStep != 6 && tour.currentStep != 8 && tour.currentStep != 10"
                                    class="ma-1 body-1 font-weight-regular">Voltar</v-btn>
                                <v-btn outlined color="white" @click="tour.nextStep"
                                    class="ma-1 body-1 font-weight-regular" v-if="tour.currentStep != 2 &&  tour.currentStep != 4 && tour.currentStep != 6 && tour.currentStep != 8 && tour.currentStep != 10">Próximo</v-btn>
                                <v-btn outlined color="white" @click="tour.stop" v-if="tour.isLast && tour.currentStep != 2 &&  tour.currentStep != 4 && tour.currentStep != 6 && tour.currentStep != 8 && tour.currentStep != 10"
                                    class="ma-1 body-1 font-weight-regular">Finalizar</v-btn>
                            </div>
                        </template>
                    </v-step>
                </transition>
            </template>
        </v-tour>
    </div>
</template>
  
<script>
import { mapActions, mapGetters } from "vuex"
export default {
    name: 'my-tour',
    data() {
        return {
            
        }
    },
    computed: {
      ...mapGetters("tour", ["steps"]),
      
    },
}
</script>
<style>
.v-step__header,
.v-step {
    background: #2196F3 !important;
    z-index: 9999;
    border-radius: 20px !important;
}
.v-tour__target--highlighted {
    box-shadow: 0 0 0 99999px rgba(0, 0, 0, .4) !important;
}
.v-step__arrow--dark:before{
    background: #2196F3 !important;
}
.overlay{
    z-index: 9999;
}
</style>
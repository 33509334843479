import apollo from '@/plugins/apollo';
import { site } from '@/graphql/Dashboard/site';

export const getSite = async (variables) => {
    const response = await apollo.query({
      query: site,
      variables
    });
    
    return response.data.site
  };
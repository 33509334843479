import gql from 'graphql-tag'

export const createCreative = gql`
mutation createCreative($content: CreativeContent!) {
  createCreative(content: $content) {
    code
    success
    message
    data {
      creative_id
    }
  }
}`;
import ButtonNextStep from '@/components/ButtonNextStep/index.vue'
import { mapActions, mapGetters } from "vuex"
import { storeCampaigns } from '@/services/Dashboard/createCampaigns'
import { updateCampaign } from '@/services/Dashboard/updateCampaign'
import moment from 'moment/moment'

export default {
    components:{
        ButtonNextStep
    },
    data: () => ({
        stepPayment: [
            { text: 'Diário', icon: 'mdi-web', type: 'checkbox' },
            { text: 'Período', icon: 'mdi-map-marker', type: 'checkbox' },
            { text: 'Investimento', icon: 'mdi-account', type: 'checkbox' },
            { text: 'Pagar com o cartão', icon: 'mdi-calendar', type: 'checkbox' },
        ],
        menu2: false,
        isLoading: false,
        form: {
            isDiary: false,
            value: 0,
            campaign_start_datetime: null,
            campaign_end_datetime: null
        },
        date: null
    }),
    computed: {
		...mapGetters("campaign", ["campaign", "indexCampaign"]),
		...mapGetters("auth", ["dataTeam","site","user"]),

        validateDate(){
            this.date == null
        }
	},
    methods:{
        ...mapActions("campaign",["nextStepCampaigns", "find"]),
        ...mapActions("notify",['notify','notifyShow']),

        allowedDates(val) {
            if(this.form.campaign_start_datetime){
                return val
            }
            var otherDate = new Date().toISOString().split("T")[0];
            return val >= otherDate ? val : ''
        },
        
        async createCampain() {
            try {
                this.isLoading = true
                let campaign_variables = {
                    content: {
                        team_id: this.dataTeam?.team_id,
                        advertiser_id: this.user?.advertiser_id,
                        account_id : this.user?.advertiser_id,
                        campaign_name: this.campaign.campaign_name,
                        campaign_admanager_data: JSON.stringify({
                            state: this.campaign.state, 
                            city: this.campaign.city, 
                            age_ranger_start: this.campaign.age_ranger[0], 
                            age_ranger_end: this.campaign.age_ranger[1], 
                            public: this.campaign.public
                        }),
                        campaign_start_datetime: this.form.campaign_start_datetime,
                        campaign_end_datetime: this.form.campaign_end_datetime,
                        campaign_delivery_limit_check: false,
                        campaign_emails: this.user?.user_email ?? 'sandrofilho36@gmail.com'
                    }
                }
                
                await storeCampaigns(campaign_variables).then(result=>{
                    this.nextStepCampaigns(result)
                    this.notify({message: 'Campanha criada com sucesso!Agora envie um arquivo para anunciar',type: 'success'})
                    this.notifyShow(true)
                    setTimeout(() => { this.notifyShow(false) }, 3000);
                    this.$router.push({ name: "ContentCampaign", params: { campaign_id: result.campaign_id } });
                }).catch(e=>{
                    this.notify({message: e.message , type: 'error'})
                    this.notifyShow(true)
                    setTimeout(() => { this.notifyShow(false) }, 3000);
                    this.isLoading = false
                    return 
                })
                this.isLoading = false
                
                
            } catch (error) {
                this.notify({message: error.message , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
                this.isLoading = false
                return 
            }
        },

        async updateCampain() {
            try {
                this.isLoading = true
                let campaign_variables = {
                    content: {
                        campaign_id: this.campaign?.campaign_id ?? this.indexCampaign.campaign_id,
                        campaign_name: this.campaign?.campaign_name ?? this.indexCampaign.campaign_name,
                        team_id: this.dataTeam?.team_id,
                        advertiser_id: this.user?.advertiser_id,
                        account_id : this.user?.advertiser_id,
                        campaign_admanager_data: JSON.stringify({
                            state: this.campaign?.campaign_id ? this.campaign?.state : this.form.campaign_admanager_data.state, 
                            city: this.campaign?.campaign_id ? this.campaign?.city : this.form.campaign_admanager_data.city, 
                            age_ranger_start: this.campaign?.campaign_id ? this.campaign?.age_ranger[0] : this.form.campaign_admanager_data.age_ranger_start, 
                            age_ranger_end: this.campaign?.campaign_id ? this.campaign?.age_ranger[1] : this.form.campaign_admanager_data.age_ranger_end, 
                            public: this.campaign?.campaign_id ? this.campaign.public : this.form.campaign_admanager_data.public
                        }),
                        campaign_start_datetime: this.form.campaign_start_datetime,
                        campaign_end_datetime: this.form.campaign_end_datetime,
                    }
                }
                
                await updateCampaign(campaign_variables).then(result=>{
                    this.nextStepCampaigns(result)
                    this.notify({ message: 'Campanha atualizada com sucesso!',type: 'success'})
                    this.notifyShow(true)
                    setTimeout(() => { this.notifyShow(false) }, 3000);
                    this.$router.push({ name: "ListCampaign" });
                }).catch(e=>{
                    
                    this.isLoading = false 
                    this.notify({message: e.message , type: 'error'})
                    this.notifyShow(true)
                    setTimeout(() => { this.notifyShow(false) }, 3000);
                    this.isLoading = false
                    return 
                })
                this.isLoading = false 
                
            } catch (error) {
                this.isLoading = false 
                this.notify({message: error.message , type: 'error'})
                this.notifyShow(true)
                setTimeout(() => { this.notifyShow(false) }, 3000);
                this.isLoading = false
                return 
            }
        },
        formatDateDMYtoYMD(dateString) {
            const parts = dateString.split('/');
            if (parts.length !== 3) {
              throw new Error('Invalid date format');
            }
          
            const day = parts[0];
            const month = parts[1];
            const year = parts[2];
          
            return `${year}-${month}-${day}`;
        },
        async load(){
            this.isLoading = true

            if(this.$route.params.campaign_id){
                let content = {
                    filter: `{ 'campaign_id': ${this.$route.params.campaign_id}}`
                  }
                await this.find(content)
                
                let campaign = this.indexCampaign
                let campaign_admanager_data = JSON.parse(this.indexCampaign.campaign_admanager_data)
                              
                this.form.campaign_admanager_data = campaign_admanager_data
                this.form.isDiary = false
                this.form.value = campaign.placements[0].placement_purchase_qty
                this.form.campaign_start_datetime = this.formatDateDMYtoYMD(campaign.campaign_start_datetime)
                this.form.campaign_end_datetime = this.formatDateDMYtoYMD(campaign.campaign_end_datetime)
                this.date = [this.formatDateDMYtoYMD(campaign.campaign_start_datetime), this.formatDateDMYtoYMD(campaign.campaign_end_datetime)]
            }

            this.isLoading = false
        }

    },
    watch: {
        date(value){
            if(typeof value != 'string' && value.length > 1){
                this.form.campaign_start_datetime = value[0]
                this.form.campaign_end_datetime = value[1]
            }else{
                var time = new Date(value);
                var otherDate = new Date();
                
                otherDate.setDate(time.getDate() + 1);
                otherDate.setMonth(time.getMonth() + 1);

                this.form.campaign_start_datetime = otherDate.getFullYear() + '/' + 
                ( (otherDate.getMonth()) >= 10 ? 
                (otherDate.getMonth()) : 
                '0' + (otherDate.getMonth()))  
                + '/'+ 
                (((otherDate.getDate()) >= 10) ? 
                (otherDate.getDate()) : 
                '0' + (otherDate.getDate()))
                
                this.form.campaign_end_datetime = otherDate.getFullYear() +'-' + ( (otherDate.getMonth()) >= 10 ? (otherDate.getMonth()) : '0' + (otherDate.getMonth()))  + '-'+ (((otherDate.getDate() + 1) >= 10) ? (otherDate.getDate() + 1) : '0' + (otherDate.getDate() + 1))
            }
        },
    },
    created(){
        this.load()
    }
}
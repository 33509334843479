import apollo from '@/plugins/apollo';
import { campaigns } from '../../graphql/Dashboard/getcampaigns';
import { createCampaign } from '@/graphql/Dashboard/createCampaign';

export const getCampaigns = async (variables) => {
  const response = await apollo.query({
    query: campaigns,
    variables
  });
  
  return response.data.campaigns;
};
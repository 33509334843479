import logoDefault from "../../../assets/imgs/Caixa.png"
import { login } from '../../../services/Auth/auth.js'
import { formatError } from "@/helpers/filters";
import { mapGetters } from "vuex"

export default {
    data: () => ({
        user: {
            userEmail: '',
            userPassword: '',
        },
        errorMessageLogin: '',
        dialog: false,
        logoDefault,
        rulesEmail: [
            (value) => !!value || "Campo obrigatório",
            (value) => {
            const pattern =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Email inválido";
            },
        ],
        rulesPassword: [
            (value) => !!value || "Campo obrigatório",
            (value) => (value && value.length >= 8) || "Min 8 caracteres",
        ],
        isLoading: false
    }),
    computed: {
		...mapGetters("auth", ["dataTeam","load", "site"]),
	},
    methods: {
        async authenticateUser() {
            if (!this.user.userEmail || !this.user.userPassword) {
                this.errorMessageLogin = formatError("Preencha as informações corretamente")
                this.dialog = true
                setTimeout(() => { this.dialog = false }, 3000);
            } else {
                try {
                    localStorage.clear();
                    this.isLoading = true
                    await login(this.user)
                    this.$router.push({ name: "ListCampaign" });
                } catch (error) {
                    this.errorMessageLogin = formatError(error.message)
                    this.dialog = true
                    setTimeout(() => { this.dialog = false }, 3000);
                    // alert('como é amigo:',error)
                }
                this.isLoading = false
            }
        }
    },
    
}
import "zingchart/es6";
import zingchartVue from "zingchart-vue";
import "zingchart/modules-es6/zingchart-maps.min.js";
import "zingchart/modules-es6/zingchart-maps-bra.min.js";
import { mapGetters } from "vuex"
export default {
    name:'BrowserChart',
    data: () => ({
        totalMobile: 0,
        totalDesktop: 0,
        chartBar: {
            gui: {
                contextMenu: {
                  button: {
                    visible: false
                  }
                }
              },
          type: "bar",
          series: [],
          height: "92%",
          width: "100%",
          plot: {
            showZero: true,
            animation: {
              effect: 4,
              method: 5,
              speed: 900,
              sequence: 1,
              delay: 1000,
            },
          },
          scaleX: {
            labels: ['Celular', 'Desktop'],
            item: {
              fontColor: "#44505E",
              fontSize: "14px",
              fontFamily: "Roboto",
            },
          },
          scaleY: {
            visible: false,
          },
          
        },
    }),
    computed: {
		...mapGetters("campaign", ["indexCampaign"]),
        chartBarData(){
            this.indexCampaign?.browsers?.forEach(element => {
                if(element?._id?.os_type == 'desktop'){
                    this.totalDesktop++
                }
                if(element?._id?.os_type == '"mobile"'){
                    this.totalMobile++
                }
            });
            this.chartBar.scaleX.labels=['Celular', 'Computador']

            this.chartBar.series.push({
                text: 'Celular',
                backgroundColor: "#2D96DA",
                values: [this.totalMobile, this.totalDesktop],
            })
            
            return this.chartBar
        }
	},
    components: {
        zingchart: zingchartVue,
    },
}
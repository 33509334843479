import apollo from '@/plugins/apollo';
import { uploadCreative } from '@/graphql/Dashboard/uploadCreative';

export const storeUploadCreative = async (variables) => {
  const response = await apollo.mutate({
    mutation: uploadCreative,
    variables
  });
  
  return response.data.uploadCreative;
};
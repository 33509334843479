import { login } from "@/services/Auth/auth"

export default {
	namespaced: true,
    state: {
        dataTeam: null,
        site: null,
        user: null,
        load: false
    },
    getters: {
		dataTeam: (state) => state.dataTeam,
		site: (state) => state.site,
		user: (state) => state.user,
		load: (state) => state.load,
	},
    mutations: {
        SET_DATATEAM(state, payload){
            state.dataTeam = payload
        },
        SET_SITE(state, payload){
            state.site = payload
        },
        SET_USER(state, payload) {
			state.user = payload
		},
        SET_LOAD(state, payload) {
			state.load = payload
		},
    },
    actions: {
        async signin({ commit }, data) {
            const res = await login(data)
			commit("SET_AUTH", { token, user })
		},
        signOut({ commit }) {
			destroyToken()
			localStorage.removeItem("@simplesLogin:user")
			commit("setLogout")
		},
        getDataTeam({ commit }, data) {
			commit("SET_DATATEAM",data)
		},
        getSite({ commit }, data) {
			commit("SET_SITE", data)
		},
        getUser({ commit }, data) {
			commit("SET_USER", data)
		},
        setLoad({ commit }, data) {
			commit("SET_LOAD", data)
		},
    },
}

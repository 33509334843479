import apollo from '@/plugins/apollo';
import { createCreativeGroup } from '@/graphql/Dashboard/createCreativeGroup.js';

export const storeCreativeGroup = async (variables) => {
  const response = await apollo.mutate({
    mutation: createCreativeGroup,
    variables
  });

  return response.data.createCreativeGroup.data;
};
import gql from 'graphql-tag'

export const uploadCreative = gql`
mutation UploadCreative($creativeId: Int!, $file: Upload!) {
  uploadCreative(creative_id: $creativeId, file: $file) {
    data
    message
    code
    success
  }
}`;
<template>
    <v-main class="tourStyle">
        <Toolbar />
        <v-container class="pt-0 h-100">
		    <transition name="fade" mode="out-in" :duration="350">
                <v-alert :type="type" v-if="show" style="position: fixed; z-index: 9; width: 91%;">
                    {{ message}}
                </v-alert>
            </transition>
            <transition name="fade" mode="out-in" :duration="350">
                <router-view />
            </transition>
            
        </v-container>
    </v-main>
</template>
<script>
import Toolbar from '../Toolbar/index.vue'
import { mapGetters } from 'vuex';

export default{
    components:{
        Toolbar
    },
    computed: {
		...mapGetters("notify", ["message", "type", "show"]),
	},
    
}
</script>
<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition-duration: 0.3s;
	transition-property: opacity;
	transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
	opacity: 0;
}
</style>
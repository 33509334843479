import { render, staticRenderFns } from "./CardCampaign.vue?vue&type=template&id=086e6050&scoped=true&"
import script from "./CardCampaign.vue?vue&type=script&lang=js&"
export * from "./CardCampaign.vue?vue&type=script&lang=js&"
import style0 from "./CardCampaign.vue?vue&type=style&index=0&id=086e6050&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086e6050",
  null
  
)

export default component.exports
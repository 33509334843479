import gql from 'graphql-tag'

export const storeAdvertiser = gql`
mutation RoverSignup($content: RoverSignupContent) {
  roverSignup(content: $content) {
    code
    message
    data
    success
  }
}`
import apollo, { onLogin } from '@/plugins/apollo';
import { auth } from '../../graphql/Auth/auth'
import { LOGOUT } from '../../graphql/Auth/logout';

export const login = async variables => {
    const response = await apollo.mutate({
        mutation: auth,
        variables
    })
    
    const { login } = response.data
    
    const sessionId = JSON.parse(login.data)
    await onLogin(apollo, sessionId.sessionId)
    return login
}

export const logout = async () => {
    const response = await apollo.mutate({
        mutation: LOGOUT,
    })
    return response
}
import logoDefault from '@/assets/imgs/Caixa.png'
import { mapGetters } from "vuex"
import { formatError } from "@/helpers/filters";
import { register } from '@/services/Auth/register';

export default {
  data: () => ({
    logoDefault,
    user: {
      user_name: '',
      advertiser_name: '',
      user_email: '',
      advertiser_document: '',
      user_phone: ''
    },
    errorMessageLogin: '',
    dialog: false,
    type: null,
    required: [value => !!value || 'Campo obrigatório.'],
    rulesEmail: [
      (value) => !!value || "Campo obrigatório",
      (value) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido";
      },
    ],
    rulesPassword: [
      (value) => !!value || "Campo obrigatório",
      (value) => (value && value.length >= 8) || "Min 8 caracteres",
    ],
    isLoading: false
  }),
  computed: {
    ...mapGetters("auth", ["dataTeam"]),
  },
  methods: {
    async createUser() {
      
      if (!this.user.user_email) {
        this.errorMessageLogin = formatError("Informe os campos vazios!")
        this.type = 'error'
        this.dialog = true
        setTimeout(() => { this.dialog = false }, 3000);
      } else {
        
        try {
          localStorage.clear();
          let advertise_variables = {
            content: {
              advertiser_name: this.user.advertiser_name,
              advertiser_document:this.user.advertiser_document,
              team_id: this.dataTeam.team_id,
              user_email: this.user.user_email,
              user_phone: this.user.user_phone,
              user_name: this.user.user_name,
            },
          }

          this.isLoading = true
          const res = await register(advertise_variables)
          if(res.success){
            this.errorMessageLogin = formatError(res.message)
            this.type = 'success'
            this.dialog = true
            setTimeout(() => { this.dialog = false }, 3000);

          }else{
            this.errorMessageLogin = formatError(res.message)
            this.type = 'error'
            this.dialog = true
            setTimeout(() => { this.dialog = false }, 3000);
          }

        } catch (error) {
          this.errorMessageLogin = formatError(error.message)
          this.type = 'error'
          this.dialog = true
          setTimeout(() => { this.dialog = false }, 3000);
        }
        this.isLoading = false

      }
    }
  },
}
import gql from 'graphql-tag'

export const UPDATE_CAMPAIGN = gql`
mutation updateCampaign($content: CampaignContent!) {
  updateCampaign(content: $content) {
    code
    success
    message
    data {
      campaign_id
    }
  }
}`;